@import "../../../shared/shared.scss";

.accordion_item {
    margin-top: 20px;
    margin-bottom: 10px;
}

.answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}

.answer_wrapper.open {
  margin-top: 10px;
  height: 100%;  
}

.accordion {
  padding-top: 70px; 
  list-style-type: none;
}

.active-text {
  color: $primaryTextColour;
  font-family: 'Brown';
  font-size: 22px;
  height: 100%;
  width: 95%;
  text-align: left;
}

.inactive-text {
  color: $highlightTextColour;
  font-family: 'Brown';
  font-size: 22px;
  width: 95%;
  text-align: left;
  transition: all 0.2s ease-out;
		&:hover {
			color: $primaryTextColour;
		}
}

.active-sub-text {
  color: $primaryTextColour;
  font-family: 'Brown';
  font-size: 18px;
  height: 100%;
  width: 85%;
  text-align: left;
}

.inactive-sub-text {
  color: $highlightTextColour;
  font-family: 'Brown';
  font-size: 18px;
  width: 85%;
  text-align: left;
  transition: all 0.2s ease-out;
		&:hover {
			color: $primaryTextColour;
		}
}

.display {
  cursor: pointer;
  height: 100%;

}

.none {
  display: none;
  height: 30px;
}

ul li{
  :hover {
    color: $primaryTextColour;
  }
}