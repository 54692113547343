
@import "../shared/shared.scss";


.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 1920px; //keeps perspective, and scales relative to window width within App.js
    height: 1080px;
    overflow: hidden;
    transform-origin: top left;
}


.loginContainer {
    position: absolute;
    width: 300px;
    height: 250px;
    top: 150px;
    left: 50%;
    border-radius: 20px;
    margin-left: -150px;
    padding: 30px;
    background: rgb(0, 0, 0);
    label, input, p, button {
      display: block;
      margin-bottom: 15px;
      @include views-side-menu;
      font-size: 18px;
      color: rgb(255, 255, 255);
    }
    label {
      @include views-side-menu;
    }
    input, p {
      width: 100%;
      color: rgb(0, 0, 0);
    }
    p {
      display: none;
      font-style: italic;
    }
    button {
      padding: 10px 20px;
      border-radius: 5px;
      background: white;
      color: black;
      &:hover {
        background: rgb(200, 200, 200);
      }
    }
  }