.gallery--image {
    position: absolute;
    &:after {
        position: absolute;
        content: ' ';
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        opacity: 0;
        transition: all 0.3s ease-out;
    }
    &:hover {
        &:after {
            opacity: 1;
        }
    }
    &.no-hover {
        &:after {
            display: none!important;
        }
    }
}