@import "../../../shared/shared.scss";

.side-menu {
    background-color: #e0ddd8;
    width: 10%;
    height: 100%;
    margin-top: 101px;
    margin-left: auto;
    position: absolute;
    right: 50px;
    border: 2px solid white;
    border-radius: 15px;
}