@font-face {
  font-family: 'Forum';
  src: url('./fonts/Forum.eot');
  src: url('./fonts/Forum.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Forum.woff2') format('woff2'),
      url('./fonts/Forum.woff') format('woff'),
      url('./fonts/Forum.ttf') format('truetype'),
      url('./fonts/Forum.svg#Forum') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SohneBreit';
  src: url('./fonts/SohneBreit-Buch.eot');
  src: url('./fonts/SohneBreit-Buch.eot?#iefix') format('embedded-opentype'),
      url('./fonts/SohneBreit-Buch.woff2') format('woff2'),
      url('./fonts/SohneBreit-Buch.woff') format('woff'),
      url('./fonts/SohneBreit-Buch.ttf') format('truetype'),
      url('./fonts/SohneBreit-Buch.svg#SohneBreit-Buch') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Copyright Klim Type Foundry';
  src: url('./fonts/SohneBreit-Buch_1.eot');
  src: url('./fonts/SohneBreit-Buch_1.eot?#iefix') format('embedded-opentype'),
      url('./fonts/SohneBreit-Buch_1.woff2') format('woff2'),
      url('./fonts/SohneBreit-Buch_1.woff') format('woff'),
      url('./fonts/SohneBreit-Buch_1.ttf') format('truetype'),
      url('./fonts/SohneBreit-Buch_1.svg#SohneBreit-Buch') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NHaasGroteskTXPro';
  src: url('./fonts/NHaasGroteskTXPro-55Rg.ttf');
  
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/* @font-face {
    font-family: Brown;
    src: url('./fonts/BrownFont/Brown-Regular.otf');
  }
@font-face {
  font-family: 'Sohne-Kraftig';
  src: url('./fonts/Sohne-Kraftig.otf');
}

@font-face {
  font-family: 'Sohne-Halbfett';
  src: url('./fonts/Sohne-Halbfett.otf');
}


@font-face {
  font-family: 'Sohne-Leicht';
  src: url('./fonts/Sohne-Leicht.otf');
}
@font-face {
  font-family: 'PPEiko-Thin';
  src: url('./fonts/PPEiko-Thin.otf') format('otf');
}

@font-face {
  font-family: 'PPEiko-Thin-100';
  src: url('./fonts/PPEiko-Thin.otf') format('ttf');
} */
