@import "../../shared/shared.scss";


.aerial-map--transform-container {
    position: absolute;
    top: -100px;
    left: 0;
    width: #{( 1920 - 150 )}px;
    height: #{( 980 - 0 )}px;
}

.aerial-map--frg {
    position: absolute;
    left: 0;
    top: 0;
}


.aerial-map--menu {
    position: absolute;
    top: 0px;
    width: 500px;
    height: 1180px;
    right: -240px;
    background-color: #e0ddd8;

}


.aerial-map--menu-options {
    position: absolute;
    top: 200px;
    right: 80px;
    left: 45px;
}


.aerial-map--title {
    position: relative;
    left: -9px;
    display: block;
    margin: 50px 0px 20px 0px;
    color: $highlightTextColour;
    //font-size: 26px;
    //@include font-secondary;
    font-size: 16px;
    font-family: 'SohneBreit';
    letter-spacing: -0.2px;
    font-weight: 500;
}


.aerial-map--btn {
    position: relative;
    display: block;
    margin: 0px 0px 12px 0px;
    padding: 0px 0px 0px 26px;
    color: $highlightTextColour;
    //font-size: 16px;
    font-size: 14px;
    text-align: left;
    //@include font-primary;
    //font-family:"ivymode";
    @include font-body;
    cursor: default;

    .number {
        position: absolute;
        left: -10px;
        top: -1px;
        width: 26px;
        height: 26px;
        text-align: center;
        background-color: $highlightTextColour;
        color: 'white';
        padding: 4px 5px;
        border-radius: 55px;
        color: white;
        font-size: 12px;
    }
}


.aerial-map--btn__active {
    @extend .aerial-map--btn;
    color: $primaryTextColour;
}


.aerial-map--zoom-tools {
    display: flex;
    justify-content: space-between;
    z-index: $coreMenuZIndex - 2;
    position: absolute;
    left: -110px;
    font-family: 'Brown';
    top: 1100px;
    height: 40px;
    text-align: center;
    transform: translateX(-70%);
    background-color: #e0ddd8;
    border-radius: 25px;
    padding: 3px 0px;
    height: 45px;
    width: 100px;
    border: 2px solid $highlightTextColour;

    // pointer-events: none;
    button {
        margin: 0px 5px;
        cursor: pointer;

        // transition: all 0.2s ease-out;
        // pointer-events:all;
        &:hover {
            color: $primaryTextColour;
        }
    }
}

.aerial-map--zoom-tools--title {
    margin: 10px auto 20px;
    @include font-primary;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
}