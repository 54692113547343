@import "../../shared/shared.scss";

.movies--nav-link__movies {
    position: absolute;
    left: 60px;
    top: 0px;
    width: 170px;
    height: 80px;
}

.movies--button__ird {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

.movies--enlarged {
    z-index: $coreMenuZIndex + 11;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
}

.movies--enlarged__movie {
    position: absolute;
    left: 0px;
    top: 0px;
}

.movies--enlarged__close-btn {
    color: #fff;
    background-size: 40px 40px;
    transition: all 0.3s ease-out;
    color: rgb white;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: .3em;
    margin: 0 0 0.2em 0.3em;
    opacity: .6;
    position: absolute;
    right: 50px;
    text-shadow: 0 0 6px #000;
    text-transform: uppercase;
    top: 20px;
    transition: all .3s ease-out;
    &:after {
        content: "×";
        font-family: serif;
        font-size: 2em;
        position: absolute;
        right: -30px;
        top: -0.35em;
    }
    &:hover {
        opacity: 0.5;
    }
}